@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@font-face {
  font-family: "AdamGorry-Lights";
  src: url("../public/assets/fonts/AdamGorry-Lights.woff2") format("woff2"),
    url("../public/assets/fonts/AdamGorry-Lights.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bright Lights";
  src: url("../public/assets/fonts/Bright-Lights-Bold.woff2") format("woff2"),
    url("../public/assets/fonts/Bright-Lights-Bold.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: "Bright Lights";
  src: url("../public/assets/fonts/Bright-Lights-.woff2") format("woff2"),
    url("../public/assets/fonts/Bright-Lights-.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bright Lights";
  src: url("../public/assets/fonts/Bright-Lights-Thin.woff2") format("woff2"),
    url("../public/assets/fonts/Bright-Lights-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
} */

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar:vertical {
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
